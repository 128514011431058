<template>
  <div>
    <div v-if="submittingOrderItem" class="text-center p-3">
      <h4>
        {{ loaderMessage }}
      </h4>
      <ct-centered-spinner />
    </div>

    <div v-else id="review-container">
      <div class="heading-container">
        <h2>
          Take a moment to review the information you’ve entered.
        </h2>
        <h5>
          After you’ve confirmed, we’ll take it from there and transmit your paperwork to the state.
        </h5>
        <p>
          <a href="#" @click.prevent="editDetails">
            Need to make a change?
          </a>
        </p>
      </div>

      <div class="row">
        <div
          :class="objectsRequiringAttention.length > 1 ? 'col-md-6' : 'col-md-12'"
          class="column-container"
        >
          <order-item-data
            v-for="object in objectsRequiringAttention"
            :key="object.id"
            :order-item-object="object"
            :show-name="true"
          />
        </div>
      </div>

      <b-button
        class="next-button"
        variant="primary"
        aria-label="confirm and submit button"
        @click="submitORA"
      >
        <span style="padding: 0 .5rem">
          Confirm and Submit
        </span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { orderItemCompleted } from '../../../SchemaForm/helper'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'Review',
  components: {
    OrderItemData:     () => import('@/components/shared/OrderItemData'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  mixins: [makeToastMixin],
  props: {
    slide: null,
  },
  data () {
    return {
      submittingOrderItem: false,
      loaderMessage: 'Submitting Filing',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentObjectId',
      'objectsRequiringAttention',
    ]),
  },
  methods: {
    ...mapActions('stageline', [
      'setObjectIdAndField',
      'reloadSlideORA',
    ]),
    editDetails() {
      this.setObjectIdAndField({ objectId: this.objectsRequiringAttention[0].id, startingField: 'first' })
    },
    async submitORA() {
      this.submittingOrderItem = true
      for (const i in this.objectsRequiringAttention) {
        const obj = this.objectsRequiringAttention[i]
        const result = await orderItemCompleted(obj)
        if (!result[0].success) {
          this.errorToast('Error', result[0].message)
          this.submittingOrderItem = false
          break
        }
      }

      await this.reloadSlideORA()
      this.submittingOrderItem = false
    },
  },
}
</script>

<style lang="scss">
#review-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  row-gap: 1.0em;

  div.heading-container {
    width: 100%;

    p {
      margin-bottom: 0.5em;
    }
  }

  div.column-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    max-height: 45vh;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 2px 2px 5px grey;
    padding: 10px;

    hr.mobile-divider {
      display: none;
    }

    div.column {
      flex: 50%;
      padding-left: 0.8em;
    }

    h6 {
      font-weight: bold;
    }

    p {
      padding-left: 0.4em;
      margin-bottom: 0.4em;
    }
  }

  div.formation-container {
    width: 100%;
  }

  div.button-container {
    width: 100%;
    padding-top: 0.8em;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;

    .continueButton {
      align-self: flex-end;
    }
  }
}

hr {
  width: 90%;
  height: 2px;
  color: #767676;
}

.button-padding {
  padding-bottom: 1.0em;
}

.italic {
  font-style: italic;
}

@media only screen and (min-width: 661px) {
  div.heading-container {
    text-align: center;

    h5 {
      margin-top: 1em;
    }

    p {
      float: right;
    }
  }
}
@media only screen and (max-width: 660px) {
  div.column-container {
    display: flex;
    flex-flow: column nowrap !important;
    justify-content: center;
  }

  div.heading-container {
    display: flex;
    flex-flow: column nowrap !important;
    justify-content: center !important;
    align-items: flex-start !important;
  }

  hr.mobile-divider {
    display: unset !important;
    width: 90%;
    height: 2px;
    color: #767676;
  }
}

</style>
