var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.submittingOrderItem
      ? _c(
          "div",
          { staticClass: "text-center p-3" },
          [
            _c("h4", [
              _vm._v("\n      " + _vm._s(_vm.loaderMessage) + "\n    "),
            ]),
            _c("ct-centered-spinner"),
          ],
          1
        )
      : _c(
          "div",
          { attrs: { id: "review-container" } },
          [
            _c("div", { staticClass: "heading-container" }, [
              _c("h2", [
                _vm._v(
                  "\n        Take a moment to review the information you’ve entered.\n      "
                ),
              ]),
              _c("h5", [
                _vm._v(
                  "\n        After you’ve confirmed, we’ll take it from there and transmit your paperwork to the state.\n      "
                ),
              ]),
              _c("p", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.editDetails.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n          Need to make a change?\n        ")]
                ),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "column-container",
                  class:
                    _vm.objectsRequiringAttention.length > 1
                      ? "col-md-6"
                      : "col-md-12",
                },
                _vm._l(_vm.objectsRequiringAttention, function (object) {
                  return _c("order-item-data", {
                    key: object.id,
                    attrs: { "order-item-object": object, "show-name": true },
                  })
                }),
                1
              ),
            ]),
            _c(
              "b-button",
              {
                staticClass: "next-button",
                attrs: {
                  variant: "primary",
                  "aria-label": "confirm and submit button",
                },
                on: { click: _vm.submitORA },
              },
              [
                _c("span", { staticStyle: { padding: "0 .5rem" } }, [
                  _vm._v("\n        Confirm and Submit\n      "),
                ]),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }